<template>
  <div class="payment-all m-scroll">
    <header-bar title="确认订单">
      <img
        slot="left"
        src="@/assets/public/icon-fanhui_fff.png"
        alt="back"
        class="header-slot"
        @click="$router.go(-1)"
      />
    </header-bar>
    <div class="shopping-main" style="margin-top:10px">
      <div
        class="store-item m-padding">
        <div class="store-title van-hairline--bottom">
          <img src="@/assets/payment/icon_dianpu@2x.png" alt="" />
          Mooby自营
          <span>(共1件)</span>
          <div class="isSelfpick">
            <!-- <van-switch v-model="mailling" size="20px" inactive-color="#dcdee0"/> -->
            <span>自提</span>
          </div>
        </div>
        <div class="shipping">
          <div class="shipping-left">
            自提地址：Unit 133-11121 Horseshoe Way Richmond, BC, V7A5G7 Canada
          </div>
        </div>
        <div class="shipping-tip">*夜市套票还可随Mooby其他订单一起配送哦~</div>
        <div class="goods-list">
          <div
            class="goods-item">
            <img :src="goods.goods_sku.sku_pic_url" alt="" class="goods-img" />
            <div class="goods-right">
              <p class="van-multi-ellipsis--l2">{{ goods.goods_info.name }}</p>
              <div class="sku">{{goods.goods_sku.attr_symbol_string}}</div>
              <div class="total">
                <span>${{goods.goods_sku.discount_total}}</span>
                ×{{goods.goods_sku.buy_number}}
              </div>
            </div>
          </div>
        </div>

        <van-cell
          title="价格"
          :value="`$${(order.order_goods_amount_total - order.order_discount_amount_total).toFixed(2)}`"
          :border="false"
        />
        <!-- <van-cell
          :title="'PST(' + (order.city_tax.pst*100).toFixed(0) + '%)'"
          :value="`$${order.order_tax.pst}`"
          v-if="order.city_tax.pst != '0.00'"
          :border="false"
        />
        <van-cell
          :title="'GST(' + (order.city_tax.gst*100).toFixed(0) + '%)'"
          :value="`$${order.order_tax.gst}`"
          v-if="order.city_tax.gst != '0.00'"
          :border="false"
        />
        <van-cell
          :title="'QST(' + (order.city_tax.qst*100).toFixed(0) + '%)'"
          :value="`$${order.order_tax.qst}`"
          v-if="order.city_tax.qst != '0.00'"
          :border="false"
        />
        <van-cell
          :title="'HST(' + (order.city_tax.hst*100).toFixed(0) + '%)'"
          :value="`$${order.order_tax.hst}`"
          v-if="order.city_tax.hst != '0.00'"
          :border="false"
        /> -->
        <van-cell
          title="邮费"
          :value="`$${order.order_postage_amount_total}`"
          :border="false"
        />
        <!-- <van-cell
          :title="'优惠(' + order.order_discount_off_total + '%0ff)'"
          :value="`- $${order.order_discount_amount_total}`"
          :border="false"
        /> -->
        <p class="store-total">小计: ${{ (order.order_payment_amount_total*1).toFixed(2) }}</p>
      </div>
    </div>
    <div class="cart-tools noselect van-hairline--top">
      <div class="price">
        <p>合计：${{ (order.order_payment_amount_total*1).toFixed(2) }}</p>
      </div>
      <div class="calc-order" @click="calcOrder">去支付</div>
    </div>
    <SelfPick
      @submit="getOrder"
      :pickUpInfo="pickUpInfo"
    />
  </div>
</template>

<script>
import HeaderBar from "@/components/zh/header-bar.vue";
import SelfPick from "./selfPick.vue";

import { getSelfInfo, getTitle } from '@/api/zh/bargain.js'

export default {
  components: { HeaderBar, SelfPick},
  data() {
    return {
      order: {order_tax: {}, city_tax: {}}, //  订单信息
      goods: {goods_sku: {}, goods_info:{}}, //  商品信息
      shop: {}, //  商家信息
      pickUpInfo: {
        name: '',
        surname: '',
        tel: '',
        email: '',
        id: ''
      }, //  自提人信息
      orderArr: [{}],
      goodsArr: [{}],
      id: ''
    };
  },
  methods: {
    getOrder(data) {
      if(data != undefined) {
        this.id = data
      }
      this.$store.commit('load')
      getSelfInfo().then(res => {
        if(res) {
          this.goods = res.data.goods
          this.shop = res.data.shop
          this.order = res.data.order
          if(res.data.user_pick != null) {
            this.pickUpInfo = res.data.user_pick
          }
          this.goodsArr.forEach(e => {
            e.goods_name = this.goods.goods_info.name
            e.number = 1
            e.goods_price = this.goods.goods_sku.discount_total
            e.price = this.goods.goods_sku.discount_total
            e.sku_pic = this.goods.goods_sku.sku_pic_url
            e.sku_title = this.goods.goods_sku.attr_symbol_string
            e.goods_wallet_fee = 0
          })
          setTimeout(() => {
            this.orderArr.forEach(e => {
              e.city_tax = this.order.city_tax
              e.mailling = 1
              e.coupon_price = '0.00'
              e.title = 'Mooby Yoho'
              e.goods_price = (this.order.order_goods_amount_total - this.order.order_discount_amount_total).toFixed(2)
              e.order_total_amount = this.order.order_payment_amount_total
              e.gst = this.order.order_tax.gst
              e.pst = this.order.order_tax.pst
              e.hst = this.order.order_tax.hst
              e.qst = this.order.order_tax.qst
              e.goods_list = this.goodsArr
            })
          }, 500)
        }
      }).finally(() => {
          this.$store.commit('unload')
      })
    },
    calcOrder() {
      let params = {
        pick_id: this.id,
        remarks: ''
      }
      this.$store.commit('load')
      getTitle(params).then(res => {
        if(res) {
          localStorage.setItem("bargain", 1)
          localStorage.setItem("order", JSON.stringify(this.orderArr))
          localStorage.setItem("order_total", JSON.stringify({total_price: this.order.order_payment_amount_total,wallet_deduction: '0.00'}))
          
          this.$router.replace({
            path: "/zh/pay",
            query: { title: res.data.pay_title },
          });
        }
      }).finally(() => {
        this.$store.commit('unload')
      })
    }
  },
  created() {
    this.getOrder()
  },
};
</script>

<style scoped lang="less">
@import "./buy.less";
.shipping-tip {
    color: #ED2A24;
    font-size: 12px;
    padding-left: 10px;
    margin-bottom: 16px;
}
</style>

<style lang="less">
.payment-all {
  .step-total {
    width: 80px;
    height: 20px;
    margin-right: -10px;
    button,
    input {
      height: 100%;
      border-radius: 0;
    }
    input {
      border: 1px solid rgba(230, 230, 230, 1);
      background-color: #fff;
      margin: 0;
    }
    button {
      width: 20px;
      background-color: rgba(230, 230, 230, 1);
    }
  }
  .m-cat-padding {
    padding: 0;
  }
  .m-cat-devider {
    margin: 15px 0;
    font-size: 18px;
    font-weight: 500;
    color: rgba(15, 30, 51, 1);
  }
  .address-title {
    height: 44px;
    text-align: center;
    line-height: 44px;
    font-size: 16px;
    color: #666666;
    font-weight: 400;
    letter-spacing: 0.35px;
  }
  .address-btn {
    margin-top: 35px;
    padding: 0 35px;
  }

  .wallet-total {
    margin-top: -10px;
    width: 100%;
    height: 48px;
    background-color: #fff;
    > img {
      &:nth-of-type(1) {
        width: 20px;
        height: 20px;
        margin: 14px 12px;
      }
      &:nth-of-type(2),
      &:nth-of-type(3) {
        width: 18px;
        height: 18px;
        margin: 15px 16px;
        float: right;
      }
    }
    > span {
      font-size: 14px;
      color: #666;
      vertical-align: 18px;
      > span {
        color: #ed2a24;
      }
    }
  }
}

.list {
  width: 100%;
  height: calc(100% - 50px);
  padding: 10px 16px;
  overflow-x: hidden;
  overflow-y: auto;
  /deep/ .van-radio__label {
    margin-left: 8px !important;
  }
  .item {
    margin-bottom: 12px;
    background-color: #fff;
    border-radius: 5px;
    padding: 0 12px;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px 0px rgba(8, 84, 191, 0.2);
    .btns {
      display: flex;
      align-items: center;
      text-align: right;
      justify-content: flex-end;
      padding-bottom: 2px;
      & > div {
        display: flex;
        align-items: center;
        margin-left: 14px;
        & > img {
          width: 20px;
          height: 20px;
          margin-right: 4px;
        }
        & > span {
          font-size: 12px;
          font-weight: 400;
          color: #000;
          display: inline-block;
          height: 18px;
          line-height: 18px;
        }
      }
    }
    .name-phone {
      width: 100%;
      height: auto;
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > img {
        width: 100%;
        margin-right: 4px;
      }
      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > img {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
        & > span {
          font-size: 12px;
          font-weight: 400;
          color: #000;
          display: inline-block;
          height: 17px;
          line-height: 17px;
        }
      }
    }
    .addr {
      width: 100%;
      padding-left: 20px;
      padding-bottom: 0px !important;
      position: relative;
      & > img {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 0;
        left: 0;
      }
      & > p {
        font-size: 12px;
        font-weight: 400;
        color: #000;
        line-height: 17px;
        word-break: break-all;
      }
    }
    .edit {
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btns {
        display: flex;
        align-items: center;
        & > div {
          display: flex;
          align-items: center;
          margin-left: 14px;
          & > span {
            font-size: 14px;
            font-family: Avenir-Book, Avenir;
            font-weight: normal;
            color: #0854bf;
            display: inline-block;
            height: 17px;
            line-height: 17px;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
